<template>
  <b-card
    class="priceverifyruleoffline-edit-wrapper"
  >
    <b-row>
      <b-col md="1" class="ml-2">
        <div class="inner-card-title font-large-1">审批规则</div>
      </b-col>
      <b-col md="0">
        <b-button
            variant="primary"
            @click="repeateAgain"
            class="mt-0"
        >
          <feather-icon
              icon="PlusIcon"
          />
          <span>添加</span>
        </b-button>
      </b-col>
      <b-col md="8" class="ml-2">
        <div class="mt-1 mr--10">(销售价格低于最低指导价的百分比区间，触发审核流程；包含前一个值，不包含后一个值)</div>
      </b-col>
    </b-row>
    <!-- form -->
    <b-form id="priceverifyruleofflineForm" class="edit-form mt-2">
      <b-row v-for="(item,index) in priceverifyruleoffline"
             :id="item.rule_id"
             :key="item.rule_id"
             ref="row"
             class="mt-1"
      >
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="毛利率区间（%）"
              label-for="car_no"
              label-size="sm"
              class="mb-1"
          >
<!--            <vue-slider-->
<!--                v-model="item.rateSection"-->
<!--                class="mb-2"-->
<!--                dotSize="12"-->
<!--                :tooltip="'always'"-->
<!--                :tooltip-placement="['bottom', 'bottom']"-->
<!--                :enable-cross="false"-->
<!--            />-->
            <b-row>
              <b-col md="5">
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="item.rate_min"
                />
              </b-col>
              ~
              <b-col md="5">
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="item.rate_max"
                />
              </b-col>

            </b-row>

          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="审批人"
              label-for="car_no"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                :options="getCodeOptions('user')"
                :clearable="true"
                class="select-size-sm"
                v-model="item.checkerModel"
                placeholder="请选择审批人"
                @input="changeSelect('checker',item,$event)"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-button
              variant="success"
              class="mr-1"
              @click="save(item)"
          >
            <feather-icon
                icon="SaveIcon"
                class="mr-25"
            />
            <span>保存</span>
          </b-button>

          <b-button
              variant="danger"
              class="mr-1"
              @click="removeItem(item.rule_id,index)"
          >
            <feather-icon
                icon="XIcon"
                class="mr-25"
            />
            <span>删除</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import priceverifyruleofflineStore from './priceverifyruleofflineStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty,isEmptys} from '@core/utils/filter'
import {codeMultiple} from "@/views/extensions/slider/code";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    VueSlider,
    vSelect,
    useToast,
  },
  methods: {
    repeateAgain() {
      this.priceverifyruleoffline.push({state: 0})
    },
  },
  data() {
    return {
      id: ref(0),
      priceverifyruleoffline: [],
      rateSection: [0,10],
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('priceverifyruleoffline')) store.registerModule('priceverifyruleoffline', priceverifyruleofflineStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('priceverifyruleoffline')) store.unregisterModule('priceverifyruleoffline')
    })

    const search = function () {
      store.dispatch('priceverifyruleoffline/search').then(res => {
        const data = res.data.data.list
        for (let i = 0; i < data.length; i++) {
          data[i].checkerModel = getCode('user',data[i].checker)
          this.priceverifyruleoffline.push(data[i])
        }
      })
    }

    const save = function (params) {
      if (isEmptys(params.rate_min)||isEmptys(params.rate_max)){
        toast.error("请填写毛利率区间!")
        return false
      }
      if (isEmpty(params.checker)){
        toast.error("请选择审批人!")
        return false
      }
      if (params.rate_min<0||params.rate_max<0){
        toast.error("毛利率不能为负!")
        return false
      }
      if (parseFloat(params.rate_max)<parseFloat(params.rate_min)){
        toast.error("区间错误!")
        return false
      }
      store.dispatch('priceverifyruleoffline/save', params).then(res => {
        if (res.data.code == 0) {
          toast.success("保存成功!")
          params.rule_id = res.data.data.rule_id
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const changeSelect = function (key,params,event) {
      if (event != null) {
        params[key] = event.value
      } else {
        params[key] = null
      }
    }

    const removeItem = function (id,index) {
      if (confirm("确定删除吗?")){
        if (isEmpty(id)){
          this.priceverifyruleoffline.splice(index, 1)
        }else {
          store.dispatch('priceverifyruleoffline/state', {id: id,state:0}).then(res => {
            toast.success("删除成功!")
            this.priceverifyruleoffline.splice(index, 1)
          })
        }
      }
    }

    return {
      save,
      removeItem,
      changeSelect,
      search,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      isEmptys,
    }
  },
  created() {
    this.search()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
